<template>
  <div class="setting-box">
    <nav-bar :title="$t('設置')" :isFixed="true" :goBack="goBack" :bgc="'background: #ffffff'" />
    <div class="box-main">
      <div class="user-item" @click="gotoAccountData">
        <div class="left">
          <div>{{ $t("賬戶資料") }}</div>
        </div>
        <div class="lang" >
          <img src="@/assets/icons/arrow-right.png" alt="" />
        </div>
      </div>
      <div class="user-item" @click="gotoAddress">
        <div class="left">
          <div>{{ $t("收貨地址") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item" @click="gotoChangePassword">
        <div class="left">
          <div>{{ $t("修改登入密码") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item" v-if="userInfo.credit||userInfo.isCheck" @click="setPaymentPassword">
        <div class="left">
          <div>{{ $t("支付密碼") }}</div>
        </div>
        <div class="lang" >
          <img src="@/assets/icons/arrow-right.png" alt="" />
        </div>
      </div>
      <div class="user-item" @click="getLangChange">
        <div class="left">
          <div>{{ $t("语言") }}</div>
        </div>
        <div class="lang" >
          <div>{{ lang }}</div>
          <img src="@/assets/icons/arrow-right.png" alt="" />
        </div>
      </div>
      <div class="user-item" @click="gotoPrivacyCenter">
        <div class="left">
          <div>{{ $t("隱私中心") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item" @click="handleRouteChange('1')">
        <div class="left">
          <div>{{ $t("關於e-Rith") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
    </div>
    <selectDialog @handleChange="handleChange" @close="hanldeClose" :defaultValue="defaultValue" :isShow="isShow" :data="columns">
    </selectDialog>
  </div>
</template> 
<script>
import mixins from "@/utils/mixins.js"
import { mapState } from "vuex"
export default {
  name:"Setting",
  mixins: [mixins],
  computed: {
    ...mapState("user", ["userInfo"])
  },
  data(){
    return{
      isShow:false,
      lang: "English",
      defaultValue: "2",
      langEnv: 2,
      columns: [
        {
          value: "2",
          label: "English"
        },
        {
          value: "3",
          label: "繁體中文"
        }
      ],
    }
  },
  created(){
    this.$store.dispatch("user/getUserInfo")
    this.langEnv = window.localStorage.getItem("langEnv") || 2
    this.lang =
      window.localStorage.getItem("langEnv") == 2 ? "English" : "繁體中文"
  },
  methods:{
    handleRouteChange(path){
      this.$router.push(`/about?id=${path}&langEnv=${this.langEnv}`)
    },
    gotoChangePassword(){
      this.$router.push("/user/password")
    },
    gotoPrivacyCenter () {
      this.$router.push("/user/privacyCenter")
    },
    gotoAddress () {
      this.$router.push("/user/address")
    },
    gotoAccountData(){
      this.$router.push("/infoPage")
    },
    setPaymentPassword(){
      this.$router.replace("/setpPayPass")
    },
    goBack () {
      this.$router.back()
    },
    getLangChange () {
      this.isShow = true
      this.defaultValue = this.langEnv
    },
    handleChange (item) {
      this.isShow = false
      window.localStorage.setItem("langEnv", item.value)
      window.location.reload()
      this.lang = item.label
    },
    hanldeClose(data){
      this.isShow = data
    }
  }
}
</script>
<style lang="scss" scoped>
.setting-box{
  padding-top:44px;
  box-sizing: border-box;
  .box-main{
    margin:16px 10px 24px;
    padding:0 16px;
    box-sizing: border-box;
    background:#ffffff;
    border-radius: 12px 12px 12px 12px;
    .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px 0;
    border-bottom: 1px solid #F8F8F8;
    &:last-child{
        border:0
      }
    img {
      width: 16px;
      height: 16px;
    }

    .left {
      display: flex;
      align-items: center;
      white-space: nowrap;
      // font-weight: bold;

      img {
        height: 32px;
        width: 32px;
      }
    }
    .lang {
    display: flex;
    align-items: center;
    color: #999999;
    div{
      font-weight: bold;
    }
  }
  }
  }
}
</style>